
import { KwikDialog } from 'kwik-vue'
import { clockToEpoc, clockToFormatDate, dateToClock } from '@/utils/clockManager'
import Vue from 'vue'

export default Vue.extend({
  name: 'FFDateTimePicker',
  components: { KwikDialog },
  props: {
    value: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default () {
        return ''
      }
    },
    persistentHint: {
      type: Boolean,
      default () {
        return false
      }
    },
    useMinutes: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      showPicker: false,
      menuPicker: false,
      valid: false,
      currentDate: '',
      pickedPreview: '',
      selectedHour: 0,
      selectedMinute: 0,
      selectedDate: '',
      hourItems: [
        {
          label: '00',
          value: 0
        },
        {
          label: '01',
          value: 1
        },
        {
          label: '02',
          value: 2
        },
        {
          label: '03',
          value: 3
        },
        {
          label: '04',
          value: 4
        },
        {
          label: '05',
          value: 5
        },
        {
          label: '06',
          value: 6
        },
        {
          label: '07',
          value: 7
        },
        {
          label: '08',
          value: 8
        },
        {
          label: '09',
          value: 9
        },
        {
          label: '10',
          value: 10
        },
        {
          label: '11',
          value: 11
        },
        {
          label: '12',
          value: 12
        },
        {
          label: '13',
          value: 13
        },
        {
          label: '14',
          value: 14
        },
        {
          label: '15',
          value: 15
        },
        {
          label: '16',
          value: 16
        },
        {
          label: '17',
          value: 17
        },
        {
          label: '18',
          value: 18
        },
        {
          label: '19',
          value: 19
        },
        {
          label: '20',
          value: 20
        },
        {
          label: '21',
          value: 21
        },
        {
          label: '22',
          value: 22
        },
        {
          label: '23',
          value: 23
        }],
      minuteItems: [
        {
          label: '00',
          value: 0
        },
        {
          label: '05',
          value: 5
        },
        {
          label: '10',
          value: 10
        },
        {
          label: '15',
          value: 15
        },
        {
          label: '20',
          value: 20
        },
        {
          label: '25',
          value: 25
        },
        {
          label: '30',
          value: 30
        },
        {
          label: '35',
          value: 35
        },
        {
          label: '40',
          value: 40
        },
        {
          label: '45',
          value: 45
        },
        {
          label: '50',
          value: 50
        },
        {
          label: '55',
          value: 55
        }]
    }
  },
  methods: {
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    save () {
      const clock = this.convertToClock()
      this.currentDate = this.pickedPreview
      this.$emit('input', clock)
      this.menuPicker = false
      this.showPicker = false
    },
    openPicker () {
      const date = new Date(clockToEpoc(this.value))
      this.selectedHour = date.getHours()
      this.selectedMinute = this.useMinutes ? date.getMinutes() : 0
      console.log(date)
      this.selectedDate = this.formatDate(this.currentDate.substr(0, 10))
      this.showPicker = true
    },
    formatDate (date: string) {
      const [day, month, year] = date.split('/')
      return `${year}-${month}-${day}`
    },
    formatSelected () {
      const clock = this.convertToClock()
      this.pickedPreview = this.clockToFormatDate(clock)
    },
    convertToClock (): number {
      const hours = this.hourItems.find(e => e.value === this.selectedHour)
      if (!hours) return -1
      const minutes = this.minuteItems.find(e => e.value === this.selectedMinute)
      if (!minutes) return -1
      const [year, month, day] = this.selectedDate.split('-')
      const d = new Date(+year, (+month) - 1, +day, hours.value, minutes.value, 0, 0)
      return dateToClock(d)
    }
  },
  mounted () {
    this.currentDate = this.clockToFormatDate(this.value)
  },
  watch: {
    selectedHour: {
      handler (val) {
        this.formatSelected()
      },
      deep: true
    },
    selectedMinute: {
      handler (val) {
        this.formatSelected()
      },
      deep: true
    },
    selectedDate: {
      handler (val) {
        this.formatSelected()
      }
    }
  }
})
